import React from "react"
import styles from "../../css/messenging.module.css"
import { FaWhatsapp } from "react-icons/fa"

const Whatsapp = text => {
  const phoneNumber = "5215520827144"
  const greetingText = "Hola, me gustaría cotizar mi proyecto con ustedes"
  return (
    <div class={styles.whatsapp}>
      <a
        href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${greetingText}`}
        target="_blank"
      >
        <FaWhatsapp />
      </a>
    </div>
  )
}

export default Whatsapp
