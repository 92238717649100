import React from "react"
import styles from "../css/title.module.css"
import Slide from "react-reveal/Slide"

const Title = ({ title, subtitle }) => {
  // The class prop must be applied to the outer-most component for this to work
  return (
    <div className={styles.wrapper}>
      <div className={styles.center}>
        <Slide bottom>
          <h4 className={styles.title}>{title}</h4>
          <h4>{subtitle}</h4>
        </Slide>
      </div>
    </div>
  )
}

export default Title
