import React from "react"
import { FaFacebook, FaInstagram, FaPinterest } from "react-icons/fa"

export default [
  {
    name: "Facebook",
    icon: <FaFacebook />,
    url: "https://www.facebook.com/PERSPECTIVASTUDIO/",
  },
  {
    name: "Instagram",
    icon: <FaInstagram />,
    url: "https://www.instagram.com/perspectiva_studio/",
  },
  {
    name: "Pinterest",
    icon: <FaPinterest />,
    url: "https://www.pinterest.com.mx/",
  },
]
