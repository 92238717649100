export default [
  {
    path: "/",
    text: "Inicio",
  },
  {
    path: "/projects",
    text: "Proyectos",
  },
  {
    path: "/contact",
    text: "Contacto",
  },
]
