import React, { useState } from "react"
import Image from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styles from "../css/navbar.module.css"
import { MdMenu } from "react-icons/md"
import links from "../constants/links"
import socialIcons from "../constants/social-icons"

// Pulling logo from Contentful
const getLogo = graphql`
  query {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 350) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Navbar = () => {
  const data = useStaticQuery(getLogo)
  const logo = data.file.childImageSharp.fluid

  const [isOpen, setNav] = useState(false)
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }
  return (
    <nav className={styles.navbar}>
      <div className={styles.navCenter}>
        <div className={styles.navHeader}>
          <Link to="/">
            <Image className={styles.logoImage} fluid={logo} alt="Logo" />
          </Link>
          <button className={styles.logoBtn} onClick={toggleNav}>
            <MdMenu className={styles.logoIcon} />
            Menu
          </button>
        </div>
        <ul
          className={
            isOpen
              ? `${styles.navLinks} ${styles.showNav}`
              : `${styles.navLinks}`
          }
        >
          {links.map((item, index) => {
            return (
              <li key={index}>
                <Link to={item.path}>{item.text}</Link>
              </li>
            )
          })}
        </ul>
        <div className={styles.navSocialLinks}>
          {socialIcons.map((item, index) => {
            return (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.icon}
                <span>{item.name}</span>
              </a>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
